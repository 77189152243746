import { isPlatformBrowser } from '@angular/common';
import { Component, DestroyRef, Inject, OnInit, Optional, PLATFORM_ID, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { Partner } from '@app/models/partner/IPartner';
import { AuthGuardService } from '@app/services/auth-guard.service';
import { GlobalcontentService } from '@app/services/globalcontent.service';
import { SettingsService } from '@app/services/settings.service';
import { fromEvent } from 'rxjs';
import { MEDIA_TOKEN } from 'tockens';

@Component({
    selector: 'clever-overmenu',
    standalone: true,
    imports: [
        MatIconModule
    ],
    templateUrl: './overmenu.component.html',
    styleUrl: './overmenu.component.scss'
})
export class OvermenuComponent implements OnInit {
    isBrowser: boolean = false;
    destroyRef = inject(DestroyRef);
    showbtns: boolean = false;
    lastposition: number = 0;
    partner: Partner;
    small: boolean = false;

    anfpath: string = '';
    contpath: string = '';
    whatsapp: string = '';
    constructor(
        @Inject(PLATFORM_ID) protected platformId: any,
        protected authguard: AuthGuardService,
        private settingsService: SettingsService,
        protected mediaObserver: MediaObserver,
        protected gservice: GlobalcontentService,
        @Optional() @Inject(MEDIA_TOKEN) private media_token: string,
    ) {
        this.isBrowser = isPlatformBrowser(this.platformId);
        this.small = this.media_token === 'xs';
    }

    ngOnInit(): void {
        if (this.isBrowser) {
            this.initMedia();
            this.checkPartner();
            this.initScroll();
        }
    }

    async initMedia() {
        this.mediaObserver.asObservable().pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((change: MediaChange[]) => {
            this.small = change[0].mqAlias === 'xs' || change[0].mqAlias === 'sm' || change[0].mqAlias === 'md';
        });
    }

    async checkPartner() {
        this.authguard.getPartner$.pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((pp) => {
            if (pp?.partnerid?.length > 0) {
                this.partner = pp;
                this.chekcAnfrageContact();
                this.getsettings();
            }
        });
    }

    async chekcAnfrageContact() {
        this.gservice.request({ partnerid: this.partner.partnerid }, 'checkanfragecontact').pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((res) => {
            if(res?.anfrage?.path) {
                this.anfpath = this.partner.partnerid + res.anfrage.path + '/' + res.anfrage.pathname;
            }
            if(res?.contact?.path) {
                this.contpath = this.partner.partnerid + res.contact.path + '/' + res.contact.pathname;
            }
        });
    }

    async getsettings() {
        this.settingsService.request({ db: this.partner.partnerid, type: 'settings' }, 'getsettings').pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((res) => {
            if (!res?.error) {
                this.whatsapp = res?.documents?.socialnetworks?.whatsapp;
            }
        });
    }

    async initScroll() {
        fromEvent(window, 'scroll').pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((e) => {
            this.lastposition = window.scrollY;
            this.checkScrollPos();
        });
    }

    checkScrollPos() {
        // faire quelque chose avec la position du scroll
        if (this.lastposition >= 550) {
            this.showbtns = true;
        } else {
            this.showbtns = false;
        }
    }
}
