import { CommonModule, DOCUMENT, isPlatformBrowser, isPlatformServer, registerLocaleData } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, DestroyRef, Inject, OnInit, Optional, PLATFORM_ID, inject } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { fromEvent } from 'rxjs';
import { AuthGuardService } from './services/auth-guard.service';
import { environment } from '../environments/environment';

import { CookiesService } from './services/cookies.service';
import { DateService } from './services/date.service';
import { ChatService } from './services/chat.service';
// import { ScreenService } from '@app/services/screen.service';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { MEDIA_TOKEN } from 'tockens';
import { Partner } from './models/partner/IPartner';
// import { SwUpdate } from '@angular/service-worker';
// import { MatBottomSheet } from '@angular/material/bottom-sheet';
// import { PagerReloadComponent } from './components/pagereload/pagereload.component';
import { FormsModule } from '@angular/forms';
import { ChatModule } from './components/chat/chat.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CookieModule } from './components/cookie/cookie.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import localede from '@angular/common/locales/de';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OvermenuComponent } from './components/overmenu/overmenu.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
registerLocaleData(localede);
@Component({
    selector: 'clever-reisen',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ChatModule,
        MatTooltipModule,
        MatFormFieldModule,
        CookieModule,
        MatIconModule,
        MatButtonModule,
        MatInputModule,
        OvermenuComponent
    ]
})
export class AppComponent implements OnInit, AfterViewInit {
    title = 'suntour.reisen';
    isBrowser: boolean = false;
    showScrollTopButton: boolean = false;
    showchatpanel: boolean = false;
    showChat: boolean = false;
    hidenNavbar: boolean = false;
    smallMode: boolean = false;
    showChatAgent: boolean = true;
    displaySite: boolean = true;
    pass: string = '';
    currentPartner: Partner;
    protected secret: string = 'cleveracvis';
    protected partnerid: string = '';
    protected lastposition: number = 0;
    destroyRef = inject(DestroyRef);

    constructor(
        protected adapter: DateAdapter<any>,
        @Inject(PLATFORM_ID) protected platformId: any,
        @Inject(DOCUMENT) protected document: any,
        protected cookieService: CookiesService,
        protected dateService: DateService,
        protected chatService: ChatService,
        protected authguard: AuthGuardService,
        // @Optional() private update: SwUpdate,
        protected cdr: ChangeDetectorRef,
        // private bottomSheet: MatBottomSheet,
        protected mediaObserver: MediaObserver,
        @Optional() @Inject(MEDIA_TOKEN) private media_token: string,
    ) {
        this.isBrowser = isPlatformBrowser(this.platformId);
        this.smallMode = this.media_token === 'xs';
    }

    scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    ngOnInit(): void {
        if (this.isBrowser) {
            if (environment.production && !this.detectRobot()) {
                // this.checkUpdate();
                this.listenOpenChat();
            }
            this.checkHidennavbar();
            this.dateService.initCals();
            this.initScroll();
            this.adapter.setLocale('de-DE');
        }
        this.checkPartner();
    }

    ngAfterViewInit(): void {
        if (isPlatformBrowser(this.platformId) && (!this.detectRobot())) {
            this.appendStyle('late_styles.css');
            this.appendScript('socketdata.js');
            this.appendScript('lazysizes.min.js');
            this.appendScript('crypto-js.js');
            this.appendScript('moment.min.js').then(loaded => {
                if (loaded) {
                    this.appendScript('moment-timezone.min.js');
                }
            });
        }
    }

    appendScript(name: string) {
        return new Promise((resolve) => {
            const script = this.document.createElement('script');
            script.src = name;
            script.async = true;
            this.document.head.appendChild(script);
            script.onload = () => {
                resolve(true);
            };
        });
    }

    appendStyle(name: string) {
        const style = this.document.createElement('link');
        style.rel = 'stylesheet';
        style.type = 'text/css';
        style.href = name;
        this.document.head.appendChild(style);
    }

    // async checkUpdate() {
    //     this.update?.checkForUpdate();

    //     this.update?.versionUpdates.pipe(
    //         takeUntilDestroyed(this.destroyRef)
    //     ).subscribe(async evt => {
    //         // console.log('UpdateService: versionUpdates', evt);
    //         switch (evt.type) {
    //             case 'VERSION_DETECTED':
    //                 // console.log(`Downloading new app version: ${evt.version.hash}`);
    //                 break;
    //             case 'VERSION_READY':
    //                 // console.log(`Current app version: ${evt.currentVersion.hash}`);
    //                 // console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
    //                 // eslint-disable-next-line no-undef
    //                 this.updateClient();
    //                 break;
    //             case 'VERSION_INSTALLATION_FAILED':
    //                 // console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
    //                 break;
    //         }
    //     });
    // }

    // updateClient() {
    //     const sheetRef = this.bottomSheet.open(PagerReloadComponent, {
    //         data: 'Update verfügbar, bitte aktualisieren'
    //     });
    //     sheetRef.afterDismissed().pipe(
    //         takeUntilDestroyed(this.destroyRef)
    //     ).subscribe(async data => {
    //         if (data && data.message === 'Abbrechen') {
    //             //
    //         } if (data && data.message === 'Aktualisieren') {
    //             await this.update.activateUpdate();
    //             // eslint-disable-next-line no-undef
    //             location.reload();
    //         }
    //     });
    // }

    async initMedia(pp) {
        this.mediaObserver.asObservable().pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((change: MediaChange[]) => {
            this.smallMode = change[0].mqAlias === 'xs' || change[0].mqAlias === 'sm' || change[0].mqAlias === 'md';
            this.checkCookis(pp);
        });
    }

    async checkPartner() {
        this.authguard.getPartner$.pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((pp) => {
            if (pp?.partnerid?.length > 0) {
                this.currentPartner = pp;
                this.showChat = !pp.hideChat && !this.detectRobot();
                this.displaySite = !(pp.partnersite?.length > 0);
                this.initGoogleapi(pp);
                if (this.isBrowser) {
                    this.initMedia(pp);
                    if (this.showChat) {
                        this.chatService.connectToServer();
                    }
                    this.checkSendScreen();
                }
            }
        });
    }

    async initGoogleapi(currentPartner) {
        if (currentPartner.googleapikey?.length > 0 && isPlatformServer(this.platformId) && !this.detectRobot()) {
            const gaScript = this.document.createElement('script');
            gaScript.setAttribute('defer', '');
            gaScript.setAttribute('src', `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${currentPartner.googleapikey}&libraries=places&callback=Function.prototype`);

            this.document.documentElement.firstChild.appendChild(gaScript);
        }
    }

    async checkHidennavbar() {
        this.authguard.gethidenHeaderFooter$.pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((e) => {
            this.hidenNavbar = e;
            this.cdr.detectChanges();
        });
    }

    async checkSendScreen() {
        // this.chatService.checkScreen()
        //     .pipe(
        //         takeUntilDestroyed(this.destroyRef),
        //     ).subscribe((data: { show: boolean, from: any }) => {
        //         // protected screenService: ScreenService
        //         if (data.show && data?.from?.username?.length > 0) {
        //             this.screenService.sharescreen((canvas) => {
        //                 // console.log(canvas)
        //                 this.chatService.sendScreen(data.from, canvas);
        //             });
        //         }
        //     });
    }

    async initScroll() {
        fromEvent(window, 'scroll').pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((e) => {
            this.lastposition = window.scrollY;
            this.checkScrollPos(this.lastposition);
        });
    }

    openChat(): void {
        this.showchatpanel = true;
    }

    closeChat(): void {
        this.showchatpanel = false;
    }

    closeChatAgent(): void {
        this.showChatAgent = false;
    }

    async listenOpenChat() {
        this.chatService.openchat$.pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((open) => {
            if (open) {
                this.openChat();
            }
        });
    }

    checkCookis(prt): void {
        if (this.isBrowser) {
            if (environment.production) {
                if (prt.partnerid?.length > 0) {
                    this.partnerid = prt.partnerid;
                    this.secret = prt.partnersite;
                    if (this.secret?.length > 0) {
                        this.displaySite = window.localStorage.getItem(prt.partnerid + '_pass') === this.secret;
                        if (this.displaySite) {
                            if (!this.detectRobot()) {
                                this.cookieService.checkCookis(prt.googleanalyticstrackingid, prt.googletagmgid, prt.googlemapid, prt.facebookpixelid, this.partnerid, this.smallMode);
                            }
                        }
                    } else {
                        if (!this.detectRobot()) {
                            this.cookieService.checkCookis(prt.googleanalyticstrackingid, prt.googletagmgid, prt.googlemapid, prt.facebookpixelid, this.partnerid, this.smallMode);
                        }
                    }
                }
            } else {
                this.partnerid = 'main';
                this.displaySite = true;
            }
        }
    }

    detectRobot(): boolean {
        if (this.isBrowser) {
            const robots = new RegExp(([
                /bot/, /spider/, /crawl/, // GENERAL TERMS
                /APIs-Google/, /AdsBot/, /Googlebot/, // GOOGLE ROBOTS
                /mediapartners/, /Google Favicon/, /GOOGLE/,
                /FeedFetcher/, /Google-Read-Aloud/,
                /DuplexWeb-Google/, /googleweblight/, /SISTRIX/,
                /bing/, /yandex/, /baidu/, /duckduck/, /yahoo/, // OTHER ENGINES
                /ecosia/, /ia_archiver/, /cortex/,
                /facebook/, /instagram/, /pinterest/, /reddit/, // SOCIAL MEDIA
                /slack/, /twitter/, /whatsapp/, /Whatsapp/, /youtube/,
                /semrush/, // OTHER
            ] as RegExp[]).map((r) => r.source).join('|'), 'i'); // BUILD REGEXP + "i" FLAG

            return robots.test(navigator.userAgent);
        } else {
            return true;
        }
    }

    checkPass(ev) {
        if (ev.keyCode === 13 && this.pass === this.secret) {
            window.localStorage.setItem(this.partnerid + '_pass', this.secret);
            this.document.body.style.overflow = '';
            this.displaySite = true;
        } else if (ev.keyCode === 13) {
            this.pass = '';
        }
    }

    checkScrollPos(lastposition) {
        // faire quelque chose avec la position du scroll
        if (lastposition >= 500) {
            this.showScrollTopButton = true;
        } else {
            this.showScrollTopButton = false;
        }
    }
}
